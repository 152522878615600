import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, List } from '@mui/material';
import { useSelector } from 'react-redux';
import ApplicationMin from '../Components/ApplicationMin';
import styled from '@emotion/styled';
import OldApplication from '../Forms/OldApplication';
import CloseIcon from '@mui/icons-material/Close';
import NewApplicationWithOld from '../Forms/NewApplicationWithOld';

const Archive = ({setApplicationRequest}) => {
    //get state
    const allOldApplications = useSelector(state => state.oldApplications.allOldApplications)
    const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
    const allCompanies = useSelector(state => state.companies.companies)
    const allContents = useSelector(state => state.сontents.allContents)
    const authUser = useSelector(state => state.auth.authUser)   
    const [oldApplications, setOldApplications] = useState({})
    const [openForm, setOpenForm] = useState(false)
    const [openFormNewApp, setOpenFormNewApp] = useState(false)
    
    const handleCloseForm = () => {
        setOpenForm(false)
    }

    const handleCloseFormNewApp = () => {
        setOpenFormNewApp(false)
    }

    useEffect(() => window.scrollTo(0, 0), []);

    const handleOpenFormProfile = (type, appId) => {
        const allAPP = allOldApplications.filter((app)=> app.id===appId)[0]        
        setOldApplications({
            allAPP, 
            delivAddr: allAddressDelivery.filter((addrs)=>addrs.id===allAPP.deliveryAddressId)[0],
            comp: allCompanies.filter((comp)=>comp.id===allAPP.clientId)[0],
            authUser
        })
        type===-1?setOpenFormNewApp(true):setOpenForm(true)
    }

    const allOldAppComponent = allOldApplications.map((appl) => <ApplicationMin key={appl.id} old={true} application={appl} allContents={allContents} allCompanies={allCompanies} openForm={handleOpenFormProfile} />).reverse()


    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    return <>       
            <Demo >
                <List dense={true}>
                    {allOldAppComponent}
                </List>
            </Demo>  
            <Dialog
                open={openForm}
                onClose={handleCloseForm}
                scroll='body'
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
                     {Object.keys(oldApplications).length === 0?"Нет данных":"Заявка № " + oldApplications.allAPP.number + " от " + oldApplications.allAPP.date + " г."}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseForm}
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <OldApplication
                        oldApplications={oldApplications}
                    />
                </DialogContent>
            </Dialog>    
            <Dialog
                open={openFormNewApp}
                onClose={handleCloseFormNewApp}
                scroll='body'
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
                     Новая заявка
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseFormNewApp}
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <NewApplicationWithOld
                        oldApplications={oldApplications}
                        handleCloseFormNewApp={handleCloseFormNewApp}
                        setApplicationRequest={setApplicationRequest}
                    />
                </DialogContent>
            </Dialog>         
    </>
}

export default Archive;
