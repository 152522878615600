import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import BottomBar from './Components/BottomBar';
import SentMessage from './Components/SentMessage';
import IncomingMessage from './Components/IncomingMessage';
import { useSelector } from 'react-redux';
import TextMessage from './Components/TextMessage';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useState } from 'react';
import IMGMessage from './Components/IMGMessage';
import PDFMessage from './Components/PDFMessage';
import FileMessage from './Components/FileMessage';

const MainChat = ({ newMessage, setStatusMessageRequest, uploadFileRequest }) => {

    const chats = useSelector(state => state.chat.chats_id)
    const chat_users = useSelector(state => state.chat.chat_users)
    const chat_messeges = useSelector(state => state.chat.chat_messeges)
    const status_messeges = useSelector(state => state.chat.status_chat_messeges)
    const authUser = useSelector(state => state.auth.authUser)
    const focusRef = React.useRef(null);
    const nowDate = dayjs(new Date())
    const [amountMessage, setAmountMessage] = useState(0)

    const getName = (uid) => {
        const u = chat_users.filter((u) => u.user_id === uid)[0]
        return u.lastName + ' ' + u.firstName
    }

    const getStatus = (id) => {
        let status = 0
        let statusArray = []
        
        if (status_messeges.length !== 0) {
            statusArray = status_messeges.filter((s)=>s.message_id===id)
        }        
        
        if (statusArray.length !== 0) { 
            status = statusArray[0].status            
        }

        return status
    }

    const getComponent = (content) => {
        if (content.type === 0) {
            return <TextMessage message={content.content} />
        }
        if (content.type === 1) {
            return <IMGMessage message={content} />
        }
        if (content.type === 2) {
            return <PDFMessage message={content} />
        }
        if (content.type === 3) {
            return <FileMessage message={content} />
        }
        return <></>
    }

    //Отправка статуса о прочтении
    const setStatus = (id) => {
        const time = (nowDate.$H > 9? nowDate.$H:'0' + nowDate.$H) + ':' + (nowDate.$m > 9? nowDate.$m:'0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        setStatusMessageRequest({message_id: id, user_id: authUser.id, status: 1, time_create: time, date_create: date});
    }

    const chat = chat_messeges.map((c) => ({ name: getName(c.user_id), status: getStatus(c.id), component: getComponent(c.content), ...c }))

    //сортируем и получаем массив дат из существующих сообщений
    const dateMessage = [...new Set(chat.map((c) => c.content.date))]

    //формируем компонент для отображения даты и присваиваем ему сообщение от этой даты
    const componentChatDate = dateMessage.map((d) => ({
        date: <Chip key={d} label={d} color="default" sx={{ color: '#07139e', m: 0.3 }} />, message: chat.filter((c) => c.content.date === d)
    }))

    //создаем массив объектов из компонента даты и сообщений от этой даты
    const componentChat = componentChatDate.map((cd, indexD) => ({
        date: cd.date, message: cd.message.map((c, index) => c.user_id === authUser.id ?
            <SentMessage key={index} message={c} refus={indexD + 1 === componentChatDate.length && index + 1 === cd.message.length ? focusRef : null} /> :
            <IncomingMessage key={index} setStatus={setStatus} message={c} refus={indexD + 1 === componentChatDate.length && index + 1 === cd.message.length ? focusRef : null} />)
    }))

    const setNewMassage = (mes) => {
        const time = (nowDate.$H > 9? nowDate.$H:'0' + nowDate.$H) + ':' + (nowDate.$m > 9? nowDate.$m:'0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        newMessage({ chat_id: chats[0].chat_id, user_id: authUser.id, content: { type: 0, content: mes, time, date } }, authUser.mycompany)
    }

    const setNewMassageFile = (mes) => {
        //type - 1:IMG, 2:PDF, 3:FILE 
        const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        newMessage({ chat_id: chats[0].chat_id, user_id: authUser.id, content: { type: mes.type, src: mes.src, name: mes.name, time, date } }, authUser.mycompany)
    }

    useEffect(()=>{

        const scrolTo = () => {
            if (chat.length !== 0) { focusRef.current.scrollIntoView() }
        }

        if (amountMessage !== chat_messeges.length) {
            setTimeout(scrolTo, 100)
            setAmountMessage(chat_messeges.length)
        }        
    }, [amountMessage, setAmountMessage, chat_messeges.length, chat.length])    

    return <>
        {/* <Typography color="error" variant="h6">
            Внимание !!! Чат находится в разработке и тестируется, он пока не доступен для работы!
        </Typography> */}

        <Box sx={{ px: 1, pb: 2 }}>
            {componentChat.map((c) => [c.date, ...c.message])}
            <BottomBar newMessage={setNewMassage} bottom={56} setNewMassageFile={setNewMassageFile} uploadFileRequest={uploadFileRequest} />

        </Box >
    </>
}

export default MainChat;
