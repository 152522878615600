import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import TableUnits from '../Components/TableUnits';
import { useSelector } from 'react-redux';

const myHelper = {
  email: {
    required: "Email is Required",
    pattern: "Invalid Email Address"
  },
  text: {
    required: "Заполните поле"
  }
};

const NewApplicationWithOld = ({ oldApplications, handleCloseFormNewApp, setApplicationRequest }) => {


  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [stateTable, setStateTable] = React.useState([]);
  const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
  const allCompanies = useSelector(state => state.companies.companies)
  const allContents = useSelector(state => state.сontents.allContents)
  const myCompony = useSelector(state => state.myCompany)

  const [deliveryAddressesState, setdeliveryAddresses] = React.useState(allAddressDelivery.filter((addr) => addr.idCompany === oldApplications.comp.id))
  const [selectDeliveryAddress, setSelectDeliveryAddress] = React.useState(oldApplications.delivAddr)

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const handleOnSubmit = (evt) => {
    // evt = { id: user.id, ...evt, img: user.img }
    // updateAuthUserRequest(evt) // send request to server
    let allTotal = 0
    const payment = false
    const clientId = evt.customer.id
    const ownerId = oldApplications.authUser.id
    const companyId = oldApplications.authUser.mycompany[0]
    const content = stateTable
    const idGroup = oldApplications.authUser.idGroup[0]
    const onDeletion = false
    const edit = false
    let deliv = 0

    if (evt.deliveryAddress !== undefined) {
      deliv = evt.deliveryAddress.id
    }

    if (stateTable.length > 0) {
      allTotal = stateTable.reduce((a, b) => a + b.total, 0)
      setApplicationRequest({ ...evt, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, edit, deliveryAddressId: deliv })
      handleCloseFormNewApp()
    } else {
      setOpenCollapse(true)
      setTimeout(() => setOpenCollapse(false), 4000)
    }

    //console.log({ ...evt, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, edit, deliveryAddressId: evt.deliveryAddress.id });

  };

  const [openKNP, setOpenKNP] = React.useState(oldApplications.allAPP.invoice);
  const handleChange = (event) => {
    setOpenKNP(event.target.checked);
  };

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="customer"
              defaultValue={oldApplications.comp}
              rules={{
                required: true
              }}

              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  disablePortal

                  value={field.value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {

                      onChange(newValue)
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setdeliveryAddresses(allAddressDelivery.filter((addr) => addr.idCompany === newValue.inputValue.id))

                      onChange(newValue.inputValue)
                    } else {
                      setSelectDeliveryAddress(null)
                      setdeliveryAddresses(allAddressDelivery.filter((addr) => addr.idCompany === newValue.id))
                      onChange(newValue)
                    }
                  }}
                  id="customersId"
                  options={allCompanies}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {

                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  renderInput={(params) =>
                    <TextField
                      fullWidth
                      {...params}

                      size="small"
                      label="Выберите клиента"
                      error={error !== undefined}
                      helperText={error ? myHelper.text[error.type] : ""}
                    />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="deliveryAddress"
              defaultValue={selectDeliveryAddress}
             
              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  disablePortal

                  value={selectDeliveryAddress}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {

                      onChange(newValue)
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setSelectDeliveryAddress(newValue.inputValue)
                      onChange(newValue.inputValue)
                    } else {
                      setSelectDeliveryAddress(newValue)
                      onChange(newValue)
                    }
                  }}

                  options={deliveryAddressesState}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {

                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.address;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.address}</li>}
                  renderInput={(params) =>
                    <TextField
                      fullWidth
                      {...params}
                      size="small"
                      label="Адрес доставки"
                      error={error !== undefined}
                      helperText={error ? myHelper.text[error.type] : ""}
                    />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} >
            <Collapse in={openCollapse}>
              <Alert severity="error">Таблица пустая! Заполните таблицу.</Alert>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <TableUnits
              myCompony={myCompony}
              allContents={allContents}
              initialContent={oldApplications.allAPP.content}
              setStateTable={setStateTable}
            />
          </Grid>

          {oldApplications.authUser.esf || oldApplications.authUser.invoce || oldApplications.authUser.nakladnayAVR ?
            <Grid item xs={12} sx={{ textAlign: 'left' }}>

              <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'left' }}>
                Сформировать
              </Typography>
              {oldApplications.authUser.invoce ?
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name="invoice"
                      defaultValue={oldApplications.allAPP.invoice}
                      render={({ field: { onChange, ...field } }) => (
                        <FormControlLabel
                          control={<Checkbox size="small" checked={field.value} />}
                          label="Счет на оплату"
                          {...field}
                          onChange={(event, newValue) => {
                            handleChange(event)
                            onChange(event)
                          }}

                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="knp"
                      defaultValue={oldApplications.allAPP.knp}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="text"
                          disabled={!openKNP}
                          fullWidth
                          label="КНП"
                          error={error !== undefined}
                          helperText={error ? myHelper.text[error.type] : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                : <></>}
              {oldApplications.authUser.nakladnayAVR ? <>
                <Controller
                  control={control}
                  name="nakladnayAVR"
                  defaultValue={oldApplications.allAPP.nakladnayAVR}
                  render={({ field }) => (
                    <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="Накладная/АВР" {...field} />
                  )}
                />
                <br />
              </>
                : <></>}
              {oldApplications.authUser.esf ?
                <Controller
                  control={control}
                  name="esf"
                  defaultValue={oldApplications.allAPP.esf}
                  render={({ field }) => (
                    <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="ЭСФ" {...field} />
                  )}
                />
                : <></>}
            </Grid>
            : <></>}

          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseFormNewApp} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default NewApplicationWithOld