import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import TableUnits from '../Components/TableUnits';

const OldApplication = ({ oldApplications }) => {
 
  return (
    <div className="App">
      <Box component="form" >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={oldApplications.comp.name}
              size="small"
              type="text"
              fullWidth
              label="Клиент"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={oldApplications.delivAddr === undefined ? " " : oldApplications.delivAddr.address}
              size="small"
              type="text"
              fullWidth
              label="Адрес доставки"
            />
          </Grid>

          <Grid item xs={12}>
            <TableUnits
              myCompony={[]}
              allContents={[]}
              initialContent={oldApplications.allAPP.content}
              setStateTable={()=>{}}
            />
          </Grid>

          {oldApplications.authUser.esf || oldApplications.authUser.invoce || oldApplications.authUser.nakladnayAVR ?
            <Grid item xs={12} sx={{ textAlign: 'left' }}>

              <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'left' }}>
                Сформировать
              </Typography>
              {oldApplications.authUser.invoce ?
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={<Checkbox size="small" checked={oldApplications.allAPP.invoice} />}
                      label="Счет на оплату"
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <TextField
                      size="small"
                      type="text"
                      disabled={!oldApplications.allAPP.invoice}
                      fullWidth
                      label="КНП"
                      value={oldApplications.allAPP.knp}
                    />

                  </Grid>
                </Grid>
                : <></>}
              {oldApplications.authUser.nakladnayAVR ? <>
                <FormControlLabel control={<Checkbox size="small" checked={oldApplications.allAPP.nakladnayAVR} />} label="Накладная/АВР" />
                <br />
              </>
                : <></>}
              {oldApplications.authUser.esf ?
                <FormControlLabel control={<Checkbox size="small" checked={oldApplications.allAPP.esf} />} label="ЭСФ" />
                : <></>}
            </Grid>
            : <></>}
        </Grid>
      </Box>
    </div>
  );
}

export default OldApplication