//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Archive from './Archive';
import { setApplicationRequest } from '../Redux/applicationsReducer';

class ArchiveContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "0 auto"}} >
                <Archive
                    setApplicationRequest={this.props.setApplicationRequest}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {}
};

export default connect(mapStateToProps, {setApplicationRequest})(ArchiveContainer)
