import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { useSelector } from 'react-redux';
import UploadButtonTask from '../Dashboard/components/UploadButtonTask';
import { useState } from 'react';


const FormViewTask = ({ openFormViewTask, handleCloseFormViewTask, openFIdTask, giveTimeDate, uploadFileTaskRequest, updateTaskCloseRequest }) => {

  const filesClosedTask = useSelector(state => state.task.filesClosedTask)
  const allFilesNewTask = useSelector(state => state.task.allFilesNewTask)

  const [open, setOpen] = useState(false);
  const [idTask, setIdTask] = useState(0)
  const [stateTask, setStateTask] = useState(0)

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    if (!openFIdTask.uploadFile) {
      alert("Вы не загрузили результат выполнения задачи!!!")
    } else {
      setIdTask(openFIdTask.id)
      setStateTask(2)
      setOpen(true)      
    }    
  };

  const handleUpdateStateTask = () => {
    updateTaskCloseRequest({ id: [idTask], state: stateTask, dataClose: giveTimeDate().date, timeClose: giveTimeDate().time }, { idUser: giveTimeDate().user })
    console.log({ id: [idTask], state: stateTask, dataClose: giveTimeDate().date, timeClose: giveTimeDate().time }, { idUser: giveTimeDate().user })
    handleClose()
    handleCloseFormViewTask()
  }

  const getComponent = (content) => {
    if (content.type === 1) {
      return <Avatar src={content.url} sx={{ height: 100, width: 100 }} variant="rounded" alt={content.name} />
    }
    if (content.type === 2) {
      return <Avatar sx={{ height: 100, width: 100 }} variant="rounded" alt={content.name} > <PictureAsPdfRoundedIcon sx={{ fontSize: 50 }} /> </Avatar>
    }
    if (content.type === 3) {
      return <Avatar sx={{ height: 100, width: 100 }} variant="rounded" alt={content.name} > <FilePresentRoundedIcon sx={{ fontSize: 50 }} /> </Avatar>
    }
    return <></>
  }

  if (openFIdTask === 0) {
    return <></>
  } else {
    return <>
    <Dialog
      open={openFormViewTask}
      onClose={handleCloseFormViewTask}
      scroll='body'
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
        Задача № {openFIdTask.id}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }} >
        <Box sx={{ minWidth: '470px' }}>
          <Grid container spacing={1} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Typography textAlign='left' color="#5d5d5d"  >
                  Компания:
                </Typography>
                <Typography textAlign='left' color="#07139e"  >
                  {openFIdTask.company.name}
                </Typography>

              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography textAlign='left' color="#5d5d5d"  >
                  Описание:
                </Typography>
                <Typography textAlign='left' color="#07139e" >
                  {openFIdTask.title}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography textAlign='left' color="#5d5d5d"  >
                  Дополнительно:
                </Typography>
                <Typography textAlign='left' color="#07139e"  >
                  {openFIdTask.captions}
                </Typography>
              </Stack>
              <Stack direction="column" spacing={0}>
                <Typography textAlign='left' color="#5d5d5d" >
                  Дополнительные документы:
                </Typography>
                {openFIdTask.file ?
                  <Stack direction="row" spacing={1}>
                    {allFilesNewTask.filter((file) => file.idTask === openFIdTask.id).map((file) =>
                      <a key={file.id} href={file.url} target="_blank" rel="noreferrer" download>
                        {getComponent(file)}
                      </a>
                    )}
                  </Stack> :
                  <Typography textAlign='left' color="#07139e"  >
                    Отсутствуют
                  </Typography>}
              </Stack>
              <Stack direction="column" spacing={0}>
                <Typography textAlign='left' color="#5d5d5d " >
                  Документы подтверждающие закрытие заявки:
                </Typography>
                {openFIdTask.uploadFile ?
                  <Stack direction="row" spacing={1}>
                    {filesClosedTask.filter((file) => file.idTask === openFIdTask.id).map((file) =>
                      <a key={file.id} href={file.url} target="_blank" rel="noreferrer" download>
                        {getComponent(file)}
                      </a>
                    )}
                  </Stack> :
                  <Typography textAlign='left' color="#07139e"  >
                    Отсутствуют
                  </Typography>}
                {openFIdTask.state === 2 ? <></> :
                  <Box sx={{ py: 1 }}>
                    <UploadButtonTask uploadFileTaskRequest={uploadFileTaskRequest} task={openFIdTask} giveTimeDate={giveTimeDate} caption={'Загрузить результат работы'} />
                  </Box>
                }
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: 1 }} >
            <Grid container spacing={1} sx={{ textAlign: 'center' }}>
              {openFIdTask.state === 2 ? <></> :
                <Grid item xs={6}>
                  <Button size="large" color="secondary" variant="outlined" onClick={handleOnSubmit} type="button" >Закрыть Задачу № {openFIdTask.id}</Button>
                </Grid>
              }
              <Grid item xs={openFIdTask.state === 2 ? 12:6}>
                <Button size="large" color="secondary" variant="outlined" type="button" onClick={handleCloseFormViewTask} >Отмена</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
    <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle style={{ cursor: 'move' }} >
          {stateTask === 2 ? "Заявка выполнена?" : "Вернуть заявку на доработку?"}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Нет
          </Button>
          <Button onClick={handleUpdateStateTask}>Да</Button>
        </DialogActions>
      </Dialog>
    </>
  }
}

export default FormViewTask